<template>
  <div>
    <TopNav></TopNav>
    <Tops></Tops>
    <div class="terms page">
      <div id="term-banner">
        <div class="text">

          <h3 class="banner-title">Terms & Conditions</h3>
          <p class="banner-subtitle">Terms & Conditions for Marketsquare </p>
        </div>
        <div class="banner-image">
          <img
            src="../assets/img/terms.svg"
            alt=""
            class="img-fluid"
          >
        </div>
      </div>
      <div class="content bg-white">
        <div class="container py-5">

          <h3 class="points-title">Terms & Conditions</h3>
          <div class="terms-content">
            <p>These Terms govern your use of the marketsquareng.com website and the Marketsquare loyalty Club (the "Site") and your relationship with Marketsquare Supermarkets. Please read them carefully as they affect your rights and liabilities under the law. If you do not agree to these Terms please do not use the Site. Please note that to buy any products on the Site, you are required to register as an authorised user and additional Terms and Conditions will apply.</p>
          </div>
          <h3 class="points-title">Use of the Site</h3>
          <div class="terms-content">
            <p>
              The Site is provided to you free of charge for your personal use subject to these Terms.
              By using the Site you agree to be bound by these Terms.
            </p>
          </div>
          <!-- points -->
          <h3 class="points-title">
            Amendments
          </h3>
          <div class="terms-content">
            <p>We may update these Terms from time to time and any changes will be either be published on this Site or notified to you via the e-mail address provided by you on registration or via any other suitable means of communication. The changes will apply to the use of the Site once updated or once notice has been given. If you do not wish to accept the new Terms you should not continue to use the Site. If you continue to use the Site after the date on which the change comes into effect, your use of the Site indicates your agreement to be bound by the new Terms.</p>
          </div>
          <!-- points -->
          <h3 class="points-title">
            Registration
          </h3>
          <div class="terms-content">
            <p>To register on the Site you must be over eighteen years of age. You must ensure that the details provided by you on registration or at any time are correct and complete. Please inform us of any changes to your information by updating your personal details to ensure that our records are correct.</p>
          </div>
          <!-- points -->
          <h3 class="points-title">
            Password and Security
          </h3>
          <div class="terms-content">
            <p>When you register to use the Site you will be asked to create a password. You must keep this password confidential and must not disclose it or share it with anyone. You will be responsible for all activities and orders that occur or are submitted under your password. If you know or suspect that someone else knows your password you should notify us by contacting Customer Services (see below for contact details). If Marketsquare has reason to believe that there is likely to be a breach of security or misuse of the Site, we may require you to change your password or we may suspend your account.</p>
          </div>
          <!-- points -->
          <h3 class="points-title">
            Calculation of discount
          </h3>
          <div class="terms-content">
            <p>
              Where an online offer states that a percentage discount will be given on a purchase, the cost of the qualifying purchases will be reduced by the stated discount percentage.
              Where purchases are stated to be offered on a "VAT-free" basis, the prices of qualifying items will be reduced by the equivalent of VAT. At the current VAT rate of 5% this equates to a discount of 4.76%.
              Supplementary charges such as delivery or postage & packing shall not be discounted unless specifically stated in the offer description.
            </p>
          </div>
          <!-- points -->
          <h3 class="points-title">
            Limitation of liability
          </h3>
          <div class="terms-content">
            <p>At all times our acceptance of an order takes place on dispatch of the order, at which point the purchase contract will be made and you will be charged for your order. All offers are subject to availability and while stocks last. Marketsquare shall not be liable to any customer or household for any financial loss arising out of the refusal, cancellation or withdrawal of any order or any failure or inability of a customer or household to receive goods ordered for any reason. In such case, Marketsquare will only be liable to a refund for the sum paid for the order by customer or household.</p>
          </div>
          <!-- points -->
          <h3 class="points-title">
            Intellectual property
          </h3>
          <div class="terms-content">
            <p>The content of the Site is protected by copyright, trademarks, database and other intellectual property rights. You may retrieve and display the content of the Site on a computer screen, store such content in electronic form on disk (but not any server or other storage device connected to a network) or print one copy of such content for your own personal, non-commercial use, provided you keep intact all and any copyright and proprietary notices. You may not otherwise reproduce, modify, copy or distribute or use for commercial purposes any of the materials or content on the Site without written permission from Marketsquare. No licence is granted to you in these Terms to use any trademark of marketsquareng.com or its affiliated companies or brands including, without limitation, the trademarks MARKETSQUARE and MARKETSQUARENG.COM.</p>
          </div>
          <!-- points -->
          <h3 class="points-title">
            Ratings and Reviews
          </h3>
          <div class="terms-content">
            <p>
              By submitting any content to the Site you agree that:
              You are the sole author of and are personally responsible for any content you submit (e.g. a written review) and you own any intellectual property rights that relate to it; all 'moral rights' that you may have in such content have been voluntarily waived by you; and
              We may contact you at the e-mail address attached to your account to request a review or to notify you about the status of your review. You agree that you won't submit anything that:
            </p>
            <ul>
              <li>is false, inaccurate, or misleading;</li>
              <li>you have been paid or rewarded for, if such compensation influenced the content of your review in any way;</li>
              <li>has been copied from anyone else, or infringes on any third party’s copyright, patent, trademark, trade secret or other proprietary rights or rights of publicity or privacy;</li>
              <li>violates any law or is considered to be offensive e.g. threatening, libelous or racially or religiously biased;</li>
              <li>includes references to other websites or any contact information (e.g. email addresses or phone numbers); or</li>
              <li>contains any computer viruses, worms or other potentially damaging computer programs or files.</li>
            </ul>
            <p>Ratings and written reviews will generally be posted within two to four business days. Marketsquare reserves the right to remove or to refuse to post any submission for any reason.</p>
          </div>
          <!-- points -->
          <h3 class="points-title">
            Marketsquare Social Media Community Rules
          </h3>
          <div class="terms-content">
            <p>We encourage comments on Marketsquare related subjects in an intelligent and constructive manner. We reflect a wide range of interests for the benefit of all of our users. Treat others as you would like to be treated. Be polite, even if you disagree. Abusive language, aggression and bullying are not allowed (whether aimed at users, individuals, Marketsquare or other companies). Distressing content is also banned. We will try to help you with any queries, but to respect your privacy we cannot ask for personal contact details on this page. It may be necessary to ask you to contact us via Private Message/Direct Message.</p>
            <p>
              To safeguard the privacy of individuals any postings including information that may identify individual's offline will be removed. Repeated duplicate postings (spam) by the same user or groups will be removed as they clutter up discussions for other users. Comments subject to legal issues (slander, defamation, contempt of court) or publicising / encouraging / endorsing illegal activity will be removed. Content with overt religious or political bias intended to incite others will be removed. Aggressive lobbying that disrupts the community for other Marketsquare users will be removed.
              Impersonating brands or other users or featuring licensed or copyright material is not allowed.
              Content containing unverified or false claims about products will be removed. Finally, Marketsquare retains the right to remove content posted on social media or block users from posting to any Marketsquare page for any other reason deemed necessary to create a helpful community.
              <br />Please respect all of Facebook's terms and
              <br />Please respect all of Twitter's terms and conditions.
              <br />Please respect all of Instagram’s terms and conditions.
            </p>
          </div>
          <!-- points -->
          <h3 class="points-title">
            Availability of the Site
          </h3>
          <div class="terms-content">
            <p>Although Marketsquare aims to offer you the best service possible, Marketsquare makes no promise that the services at the Site will meet your requirements. Marketsquare cannot guarantee that the service will be fault free. If a fault occurs in the service you should report it to the Customer Services (see below for contact details) or by email at service@sundrymarkets.ng and we will attempt to correct the fault as soon as we reasonably can. Your access to the Site may be occasionally restricted to allow for repairs, maintenance or the introduction of new facilities or services. Marketsquare will attempt to restore the service as soon as it reasonably can.</p>
          </div>
          <!-- points -->
          <h3 class="points-title">
            Marketsquare's right to suspend or cancel your registration
          </h3>
          <div class="terms-content">
            <p>Marketsquare may suspend or cancel your registration immediately at our reasonable discretion or if you breach any of your obligations under these Terms. The suspension or cancellation of your registration and your right to use the Site shall not affect either party's rights or liabilities.</p>
          </div>
          <!-- points -->
          <h3 class="points-title">
            Marketsquare's liability
          </h3>
          <div class="terms-content">
            <p>
              The content on the Site is provided for general information only. It is not intended to amount to advice on which you should rely. You must obtain professional or specialist advice before taking, or refraining from, any action on the basis of the content on the Site. Although we make reasonable efforts to update the information on the Site, we make no representations, warranties or guarantees, whether express or implied, that the content on the Site is accurate, complete or up to date.
              <br />Nothing in this paragraph applies to Marketsquare's liability in respect of products sold through the Site.
            </p>
          </div>
          <!-- points -->
          <h3 class="points-title">
            Third Party Websites
          </h3>
          <div class="terms-content">
            <p>As a convenience to Marketsquare customers, the Site includes links to other websites or material which are beyond its control. Marketsquare is not responsible in any way for content outside the Site.</p>
          </div>
          <!-- points -->
          <h3 class="points-title">
            Applicable Law
          </h3>
          <div class="terms-content">
            <p>These Terms shall be governed by and construed in accordance with the laws of the Federal Republic of Nigeria and any disputes will be decided only by the Nigerian legal system.</p>
          </div>
          <!-- points -->
          <h3 class="points-title">
            International Use
          </h3>
          <div class="terms-content">
            <p>Marketsquare makes no promise that materials on the Site are appropriate or available for use in locations outside Nigeria or any location outside where Marketsquare can deliver services.</p>
          </div>
          <!-- points -->
          <h3 class="points-title">
            Miscellaneous
          </h3>
          <div class="terms-content">
            <p>We reserve the right to vary or terminate a transaction at any time without notice. The failure of Marketsquare to take any action in respect of a breach of these terms and conditions shall not constitute a waiver of their enforceability. Marketsquare reserves its rights in respect of these terms and conditions at all times. You may not assign, sub-license or otherwise transfer any of your rights under these Terms. If any provision of these Terms is found to be invalid by any court having competent jurisdiction, the invalidity of that provision will not affect the validity of the remaining provisions of these Terms, which shall remain in full force and effect. If you breach these Terms and Marketsquare ignores this, Marketsquare will still be entitled to use its rights and remedies at a later date or in any other situation where you breach the Terms.</p>
          </div>
          <!-- points -->
          <h3 class="points-title">
            Delivery of Goods
          </h3>
          <div class="terms-content">
            <p>This Site is only for delivery of products to customers within designated cities in Nigeria. We make every effort to deliver goods within five (5) days from date of order or the estimated timescales set out on the Site; however, delays are occasionally inevitable due to unforeseen factors. Besides your right to request a refund when the delay in delivery is beyond 5 days, we shall be under no further liability for any delay or failure to deliver the products within the estimated timescales.</p>
            <p>You agree not to hold Marketsquare liable for any delay or failure to deliver products or otherwise perform any obligation as specified in these Terms and Conditions of Sale if the same is wholly or partly caused whether directly or indirectly by circumstances beyond our reasonable control.</p>
          </div>
          <!-- points -->
          <h3 class="points-title">
            Goods Return & Refund
          </h3>
          <div class="terms-content">
            <p>Before pick-up in-store or accepting good at the point of delivery, Customers are strongly advised to inspect, test and confirm that any goods paid for are in good working condition before leaving a Marketsquare store or accepting the product at the point of delivery. Once a product is delivered in good working condition, we offer a no refund, no return, no replacement policy for all goods delivered and accepted in good condition by the customer.</p>
          </div>
          <!-- points -->
          <h3 class="points-title">
            Data Protection
          </h3>
          <div class="terms-content">
            <p>Any personal information collected in relation to the use of this website will be held and used in accordant with our Privacy Policy, which is available on the Site.</p>
          </div>
          <!-- points -->
          <h3 class="points-title">
            Use of Gift Voucher Codes
          </h3>
          <div class="terms-content">
            <p>This Site may sometimes accept the use of gift voucher codes for orders placed online. The Gift voucher codes which are accepted on our Site entitle you at the time of ordering a product to a saving on the order being placed on our Site. Gift vouchers may also be issued to customers in exchange for advance payments made to us via transfer to our bank accounts for products intended to be purchased on the Site. Our gift vouchers may not be exchanged for cash. With the exception of vouchers issued in accordance with our refunds policy and vouchers issued in exchange for advance payments, we reserve the right to cancel or withdraw our voucher codes at any time.</p>
          </div>
          <!-- points -->
          <h3 class="points-title">
            Site Limitations
          </h3>
          <div class="terms-content">
            <p>
              In this section, "Content" means any image, photograph, picture, text, artwork, file, sound, graphic, data or other similar materials submitted or uploaded to, via, through or in connection with the Site and "Media" means any means of conveying information, whether now known or hereafter devised.
              Marketsquare and any Marketsquare subcontractor disclaim all warranties and limit all liabilities arising out of or related to the Site to the fullest extent permissible under law. In particular, both Marketsquare and any Marketsquare subcontractor or representative disclaim all liabilities in connection with the following:
            </p>
            <ul>
              <li>Incompatibility of the Site with any of your equipment, software or telecommunications links.</li>
              <li>Any loss or damage caused to any of your equipment by use of the Site.</li>
              <li>Technical problems including errors or interruptions of the Site.</li>
              <li>Unsuitability, unreliability or inaccuracy of the Site.</li>
              <li>Inadequacy of the Site to meet your requirements.</li>
            </ul>
            <p>You hereby grant to Marketsquare and where applicable any Marketsquare subcontractor or representative a worldwide, perpetual, irrevocable, nonexclusive, royalty free, fully-paid up, transferable right and license, with the right to sublicense (through multiple tiers) in respect of all of your intellectual property and other rights in the Content to copy, make derivative works of, distribute, transmit, publicly perform, publicly display, modify or adapt the Content, in whole or in part, for any and all purposes, in any manner, using any Media.</p>
            <p>You acknowledge that Marketsquare owns or is the licensee to all right, title and interest in and to this Site, including all rights under patent, copyright, trademark and all other proprietary rights including all applications, renewals, extensions and restorations thereof, granted by and under any and all applicable laws.</p>
            <p>You warrant that you will not modify, adapt, translate, prepare derivative works from, decompile, reverse-engineer, disassemble, or otherwise attempt to derive source code from the Site.</p>
            <p>The Site is owned and operated by Sundry Markets Limited – owners of Marketsquare Supermarkets - a company registered in Nigeria whose registered office is 23 Nzimiro Street, Old GRA, Port Harcourt, Rivers State, Nigeria.</p>
            <p>
              If you have any queries please contact Customer Services at
              <a href="mailto:service@sundrymarkets.ng">service@sundrymarkets.ng</a>
              <!-- or 0700 278 6379 or 08156592811 from a mobile. -->
              <br />Company Registration No. 1181249, TIN No. 17810525
              <br />©<span id="current-year"></span> marketsquareng.com All Rights Reserved
            </p>
          </div>

        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>


<script>
import TopNav from '@/components/TopNav.vue'
import Footer from '@/components/Footer.vue'
import Tops from '@/components/Tops.vue'
export default {
  name: 'Terms',
  components: {
    TopNav, Footer,Tops
  },
  data () {
    return {
    }
  },
  watch: {
    $route: {
        immediate: true,
        handler(to, from) {
            document.title = 'Terms & Conditions Page';
        }
    },
  },
  beforeMount () {
    this.$store.dispatch('ToggleShowSearch', true)
  },
}

</script>

