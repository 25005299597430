<template>
  <div class="cartcomponent">

    <!-- cart pull-out button section -->
    <div id="cart-icon" data-toggle="modal" data-target="#sidecart" @click="hideChat">
      <p id="cart-count">
        <span class="material-icons" style="position:relative;top:3px"> local_mall </span>
        <span> {{cart.length + foodcart.length}} Item<span v-if="(cart.length + foodcart.length) >1">s</span></span>
      </p>

      <button id="cart-price">₦{{formatPrice(cart_total + food_cart_total)}}</button>
    </div>


    <!-- Sidecart Modal section -->
    <div class="modal fade" id="sidecart" tabindex="-1" role="dialog" ref="cartm" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div class="modal-dialog">
        <div class="modal-content">

          <!-- cart after-pull-out header -->
          <div class="modal-header">
            <p class="count modal-title">
              <span class="material-icons" style="position: relative;top:4px"> local_mall </span>
              <span> {{cart.length + foodcart.length}} Item<span v-if="(cart.length + foodcart.length) >1">s</span></span>
            </p>

            <button type="button" class="close" data-dismiss="modal" aria-label="Close" @click="showChat" title="Continue shopping">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <!-- cart after-pull-out body -->
          <div class="modal-body pt-0">
            <div v-if="(getCart.length + getfoodCart.length) > 0" class="">
              <div class="cart_container">


                <!-- cart display for food only -->
              <!--  <p class="modal-header mb-0 pl-0 pr-0 pb-1" ><b>Prepared Food </b> ({{foodcart.length}})</p>-->
              <hr  />
              <ul class=" cartheader"
              >
                <li class="carttitle"  style="width:70%;"><b>Prepared Food </b>({{foodcart.length}})</li>
                <li class="cartprice" style="width:25%"><b>₦ {{formatPrice($store.getters.food_cart_total)}}</b></li>
                <li class="expand" style="width:10%">
                  <div class="dropbtn" style ="float:left" @click="isShowF = !isShowF">
                    <i v-if="!isShowF" class="fa fa-angle-down" style="font-size: 21px;"></i> 
                    <i v-else class="fa fa-angle-up" style="font-size: 21px;"></i>
                  </div>
                </li>
              </ul>
            <!--
               <div class="row "  >
               <div style="margin-right: 174px;margin-left: 15px;"><b>Prepared Food </b>({{foodcart.length}})</div>
               <div style="margin-right: 22px;"><b>₦ {{formatPrice($store.getters.food_cart_total)}}</b></div>
               <div class="dropbtn" style ="float:left" @click="isShowF ? isShowF = false : isShowF = true" >
               <i class="fa fa-angle-down" style="font-size: 21px;"></i></div>
               
                </div>-->

               
                 <hr style="margin-top: 4px;" />
                 <div v-if="isShowF">
                   <div  v-for="(row, index) in getfoodCart" class="cart-item dropbtn row" v-bind:key="index">
                  <div class="col-3 p-0">
                    <img v-if="row.product.img_url.includes('https://cdn.marketsquareng.website')" :src="row.product.img_url" alt="" class="img-fluid">
                    <img v-else :src="url+row.product.img_url" alt="" class="img-fluid">
                  </div>

                  <div class="col-7">
                    <p class="name mb-0">{{row.product.name}}</p>
                    <p class="price mb-1">
                      ₦ {{formatPrice(row.product.price)}} 
                      <span style="color:#808080;font-size: 10px;" v-if="row.weight_type > 0">
                        ({{row.pack}} ~{{row.weight_type * 1000 }}g)
                      </span>
                    </p>

                    <div class="addq">
                      <div style="cursor:pointer;" class="value-button decrease" @click="decreasefoodQuantity('food'+index, row.product.id, row.weight_type)">-</div>

                      <input
                        v-if="row.product.name.includes('/KG') || row.product.name.includes('/ KG')"
                        oninput="validity.valid||(value='');"
                        :id="'food'+index"
                        type="number"
                        min="0.001"
                        step="any"
                        class="number"
                        v-model=row.quantity
                        @keypress="restrictChars($event)"
                        @change="inputfoodChange('food'+index, row.product.id, row.weight_type)"
                      >

                      <input
                        v-else
                        :id="'food'+index"
                        type="number"
                        min="1"
                        step="1"
                        class="number"
                        v-model=row.quantity
                        oninput="validity.valid||(value='');"
                        @keypress="restrictChars($event)"
                        @change="inputfoodChange('food'+index, row.product.id, row.weight_type)"
                      />

                      <div @click="increasefoodQuantity('food'+index, row.product.id, row.weight_type)" class="value-button increase" style="cursor:pointer;">+</div>
                    </div>
                  </div>

                  <div class="col-2">
                    <span @click="removefoodItem(row.product.id, row.weight_type)" style="color: #F44336;cursor:pointer;" class="material-icons"> clear </span>
                  </div>
                   </div>
                </div>
          
            
                <!-- cart display for groceries only -->
                  <hr  />
                    <ul class=" cartheader">
                <li class="carttitle" style="width:70%;"><b>Groceries  </b>({{cart.length}})</li>
                <li class="cartprice"  style="width:25%"><b>₦ {{formatPrice($store.getters.cart_total)}}</b></li>
                <li  class="expand"style="width:10%">
                  <div class="dropbtn" style ="float:left" @click="isShowC=!isShowC">
                    <i v-if="!isShowC" class="fa fa-angle-down" style="font-size: 21px;"></i>
                    <i v-else class="fa fa-angle-up" style="font-size: 21px;"></i>
                  </div>
                </li>
              </ul>
              
                 <hr style="margin-top: 4px;" />
                <!--<p class="modal-header mt-5 mb-0 pl-0 pr-0 pb-1" ><b>Groceries </b> ({{cart.length}})</p>-->
                <div v-if="isShowC">
                  <div v-for="(row, index) in getCart" class="cart-item row" v-bind:key="index">
                     <div class="col-3 p-0">
                        <img v-if="row.product.img_url.includes('https://cdn.marketsquareng.website')"
                             :src="row.product.img_url"
                              alt=""
                              class="img-fluid">
                        <img v-else
                             :src="url+row.product.img_url"
                             alt=""
                             class="img-fluid">
                     </div>

                     <div class="col-7">
                        <p class="name mb-0">{{row.product.name}}</p>
                        <p class="price mb-1">₦ {{formatPrice(row.product.price)}}</p>

                        <div class="addq">
                        <div style="cursor:pointer;" class="value-button decrease" @click="decreaseQuantity(index, row.product.id)">-</div>

                        <input v-if="row.product.name.includes('/KG') || row.product.name.includes('/ KG')"
                         oninput="validity.valid||(value='');"
                         :id="index"
                         type="number"
                         min="0.001"
                         step="any"
                         class="number"
                         v-model=row.quantity
                         @keypress="restrictChars($event)"
                         @change="inputChange(index, row.product.id)" >

                        <input v-else
                               :id="index"
                               type="number"
                               min="1"
                               step="1"
                               class="number"
                               v-model=row.quantity
                               oninput="validity.valid||(value='');"
                               @keypress="restrictChars($event)"
                               @change="inputChange(index, row.product.id)" />

                        <div @click="increaseQuantity(index, row.product.id)" class="value-button increase" style="cursor:pointer;">+</div>
                     </div>
                  </div>

                  <div class="col-2">
                    <span @click="removeItem(row.product.id)" style="color: #F44336;cursor:pointer;" class="material-icons"> clear </span>
                  </div>
                  </div>
              </div>
                

              </div>                
            </div>
            
            <!-- display when all cart is empty -->
            <div v-else class="my-3 text-center">
              <img
                src="../assets/img/shop-icon.png"
                alt=""
              >
              <h5 class="title">Your cart is empty</h5>
              <p  v-if="$store.getters.cart_total == 0 && isPromo" class="small-red-focus blinking">
                <b>Add &#x20A6;{{(20000 - $store.getters.cart_total).toLocaleString()}}  for free delivery</b>
               </p>
              <button
                class="btn empty_btn"
                data-dismiss='modal'
              >Shop Now</button>
            </div>
          </div>

          <!-- Cart information area : show instrctions -->
          <div v-if="(getCart.length + getfoodCart.length) > 0" class="modal-footer">
            <p  v-if="($store.getters.cart_total + $store.getters.food_cart_total) < 20000 && isPromo" class="small-red-focus blinking">
              <b>Add &#x20A6;{{(20000 - ($store.getters.cart_total + $store.getters.food_cart_total)).toLocaleString()}} for free delivery</b>
            </p>

             <p  v-if="$store.getters.cart_total >=20000 && $store.getters.cart_total < 15000 && isPromo" class="text-center">
              <b style="font-size:16px">
               You qualify for FREE delivery! 
               </b>
                Add &#x20A6;{{(15000 - $store.getters.cart_total).toLocaleString()}} for free Gift Card.</b>
            </p>

            <p  v-if="($store.getters.cart_total + $store.getters.food_cart_total) >= 20000 && isPromo" class="text-center">
              <b style="font-size:16px"> You qualify for FREE delivery! </b>
            </p>
            
            <!--  <p  v-if="$store.getters.cart_total >= 15000 && isPromo" class="text-center">
              <b>Free Delivery + Gift Card won!</b>
            </p> 

            <p v-if="($store.getters.cart_total + $store.getters.food_cart_total ) < 1000" class="minimum text-bold" style="font-size:17px">₦1,000 Minimum</p>
                <p>{{$store.getters.cart_total < 1500 ? "1500":"" || $store.getters.food_cart_total < 1000 ?"1000":""}}</p>
             -->
             
            <div class="foodcheckout" v-if="($store.getters.food_cart_total > 0 && $store.getters.cart_total == 0) || isAllcart ==true">
              <button v-bind:disabled="$store.getters.food_cart_total < 1000" v-bind:class="$store.getters.food_cart_total < 1000? 'disable': ''" @click="handleFoodCheckout" >
                <div>
                  <p style="font-size: 12px;" class="mb-0">
                    Checkout Prepared Food {{$store.getters.cart_total > 0 ? "Only":""}}
                  </p>
                  <div>
                    <p  v-if="delivery_mode==='Delivery' && ($store.getters.food_cart_total)>= 1000 || isAllcart ===true && isAllcart ===false" class="mb-0 warn text-left" 
                      style="font-size: 10px;text-transform: lowercase;">May arrive in 45 minutes</p>
                    <p v-else>Pick up at the store</p>
                  </div>

                   <p v-if="($store.getters.food_cart_total)< 1000 || isAllcart ==true && isAllcart ==false" class="mb-0 warn text-left"
                      style="font-size: 10px;text-transform: lowercase;">₦1,000 Minimum</p>
                </div>

                <span   v-bind:disabled="$store.getters.food_cart_total < 1000" v-bind:class="$store.getters.food_cart_total < 1000? 'disabled': ''" 
                      style ="margin-top: 2px;border-radius:3px" class="foodtotal">₦ {{formatPrice($store.getters.food_cart_total)}}</span>
              </button>
            </div>
       
          
            <div class="foodcheckout" v-if="($store.getters.cart_total > 0 && $store.getters.food_cart_total == 0) || isAllcart ==true  ">
             
              <button v-bind:disabled="$store.getters.cart_total < 10000" v-bind:class="$store.getters.cart_total < 10000? 'disable': ''"  @click="handleCheckout">
                       
                <div>
                  <p class="mb-0" style="font-size: 12px;">Checkout Groceries {{$store.getters.food_cart_total > 0 ? "Only":""}} </p>
                  <div>
                     <p v-if="delivery_mode=='Delivery' && ($store.getters.cart_total) >= 10000|| isAllcart ===true && isAllcart ===false" class=" mb-0 warn text-left" 
                       style="font-size: 10px;text-transform: lowercase;">May arrive in 2 hours</p>
                      <p v-else>Pick up at the store</p>
                  </div>
                   <p v-if="($store.getters.cart_total)< 10000 || isAllcart ==true && isAllcart ==false " class=" mb-0 warn text-left" 
                       style="font-size: 10px;text-transform: lowercase;">₦10,000 Minimum</p>
                 
                </div>
               
                <span   v-bind:disabled="$store.getters.cart_total < 10000" v-bind:class="$store.getters.cart_total < 10000? 'disabled': ''" 
                      style ="margin-top: 2px; border-radius:3px"  class="foodtotal">
                       ₦ {{formatPrice($store.getters.cart_total)}}</span>
              </button>
              
              </div>
            


            <div class="checkout" v-if="$store.getters.cart_total > 0 && $store.getters.food_cart_total > 0 && isAllcart ==true">
              <button v-bind:disabled="($store.getters.cart_total) < 10000 || ( $store.getters.food_cart_total)  < 1000" 
                      v-bind:class="($store.getters.cart_total) < 10000 || ( $store.getters.food_cart_total)< 1000? 'disabled': ''" @click="handleAllCheckout">
                <span>Checkout All Items</span>
                <span class="total">₦ {{formatPrice($store.getters.cart_total + $store.getters.food_cart_total)}}</span>
              </button>
            </div>

              <div class="checkout" v-if="$store.getters.cart_total > 0 && $store.getters.food_cart_total > 0  && isAllcart ==false">
              <button   @click="isAllcart= true">
             
                <span>Checkout</span>
                <span class="total">₦ {{formatPrice($store.getters.cart_total + $store.getters.food_cart_total)}}</span>
              </button>
            </div>

          </div>

        </div>
      </div>
    </div>
  </div>
</template>


<script>
import * as $ from "jquery";
export default {
  name: 'Cart',
  props: {
    products: Array,
    home_products: Object
  },
  data () {
    return {
      isShowF: true,
      isShowC:true,
      isAllcart: false,
      delivery_mode:true,
      cart_total: 0,
      countdown_left:0,
      food_cart_total:0,
      cart: [],
      foodcart:[],
      url: this.$request.url,
      isPromo: false,
    }
  },
  created(){
     this.store = this.$store.getters.store;
      this.delivery_mode= this.store.mode
  },
  async mounted () {
     await this.getProductInCart();

    $(this.$refs.cartm).on("hidden.bs.modal", (e) => {
      let cart = this.$store.getters.cart;
      let foodcart = this.$store.getters.foodcart;

      if (this.$props.products) {
        this.$props.products.forEach(i => {
          i.hidebtn = false;
          i.hideqty = true;
          i.cart_qty = i.description.includes('/KG') || i.description.includes('/ KG') ? 1.0 : 1;
          cart.forEach(j => {
            if (i.id == j.product.id) {
              i.hidebtn = true;
              i.hideqty = false;
              i.cart_qty = j.quantity;
            }

          })
        })
      }
      else {
        this.$props.home_products.top.forEach(i => {
          i.hidebtn = false;
          i.hideqty = true;
          i.cart_qty = i.description.includes('/KG') || i.description.includes('/ KG') ? 1.0 : 1;
          cart.forEach(j => {
            if (i.id == j.product.id) {
              i.hidebtn = true;
              i.hideqty = false;
              i.cart_qty = j.quantity;
            }

          })
        })
        this.$props.home_products.beverages.forEach(i => {
          i.hidebtn = false;
          i.hideqty = true;
          i.cart_qty = i.description.includes('/KG') || i.description.includes('/ KG') ? 1.0 : 1;
          cart.forEach(j => {
            if (i.id == j.product.id) {
              i.hidebtn = true;
              i.hideqty = false;
              i.cart_qty = j.quantity;
            }

          })
        })
        this.$props.home_products.water.forEach(i => {
          i.hidebtn = false;
          i.hideqty = true;
          i.cart_qty = i.description.includes('/KG') || i.description.includes('/ KG') ? 1.0 : 1;
          cart.forEach(j => {
            if (i.id == j.product.id) {
              i.hidebtn = true;
              i.hideqty = false;
              i.cart_qty = j.quantity;
            }

          })
        })
        this.$props.home_products.commodities.forEach(i => {
          i.hidebtn = false;
          i.hideqty = true;
          i.cart_qty = i.description.includes('/KG') || i.description.includes('/ KG') ? 1.0 : 1;
          cart.forEach(j => {
            if (i.id == j.product.id) {
              i.hidebtn = true;
              i.hideqty = false;
              i.cart_qty = j.quantity;
            }

          })
        })
      }

      // var add = document.querySelectorAll('.addquantity');
      // [].forEach.call(add, function (el) {
      //   el.classList.add("hideqty");
      // });
      // var btn = document.querySelectorAll('.addtocart');
      // [].forEach.call(btn, function (el) {
      //   el.classList.remove("hideqty");
      // });
    })
   
  },
  
  computed: {
    getCart () {
      let c = this.$store.getters.cart;
      
      this.cart = c;

      let total = 0
      c.forEach(i => {
        total += Number(i.price);
      });
      this.cart_total = total;
      return c;
    },
    getfoodCart () {
      let c = this.$store.getters.foodcart;
      
      this.foodcart = c;

      let total = 0
      c.forEach(i => {
        total += Number(i.price);
      });
      this.food_cart_total = total;
      return c;
    },
    delivery_mode() {
      return this.delivery_mode;
    }
  },
  methods: {

  toggleDropdown (e) {
      document.querySelectorAll('.dropbtn').forEach(el => {

        if ((el.nextElementSibling.style.display === "block") && (el != e.target)) {
          el.classList.remove('active');
          el.nextElementSibling.style.display = "block";
        }
      })
      e.target.classList.toggle("active");
      var dropdownContent = e.target.nextElementSibling;
      if (dropdownContent.style.display === "block") {
        dropdownContent.style.display = "none";
      } else {
        dropdownContent.style.display = "block";
      }
    },

    
    async getProductInCart(){

      let cart = this.$store.getters.cart;

      let productsInCart = cart.reduce((acc,prev) => { acc.push(prev.product.id); return acc }, [])

      let req = {
        what: "getProductInCart",
        showLoader: false,
        params : {
          'cart' : JSON.stringify(productsInCart)
        }
      };

      await this.$request.makeGetRequest(req)
        .then(res => {

          // if (res.type == 'getproduct') {
            let pro = res.data.data;
            if (pro.length > 0){
              cart = cart.map((item) => {
                item.product.price = pro.find((product) => item.product.id == product.id).sellingprice
                item.unit_price = item.product.price
                item.price = item.quantity * item.unit_price
                return item
              })
            }
            this.$store.dispatch('addToCart', cart)
            this.loader.hide()
            
          // }

        })
        .catch(error => {
          
        });
    },
    

    showChat() {
      Tawk_API.showWidget();
    },

    hideChat() {
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        Tawk_API.hideWidget();
      }
      // Fetch cart details from the store
      let cart = this.$store.getters.cart;

      // Prepare items for the `view_cart` event
      let cartItems = cart.map(item => ({
          item_id: item.product.id, // Product ID
          item_name: item.product.name, // Product name
          price: item.unit_price, // Unit price
          quantity: item.quantity, // Quantity
          item_category: item.product.category || "Unknown", // Category, default to "Unknown" if undefined
      }));

      // Calculate total cart value
      let totalValue = cart.reduce((total, item) => total + item.price, 0);

      // Send the `view_cart` event to Google Analytics
      if (typeof gtag !== 'undefined') {
          gtag("event", "view_cart", {
              currency: "NGN", // Currency code
              value: totalValue, // Total value of the cart
              items: cartItems, // Cart items
          });

          console.log("view_cart event sent to Google Analytics with cart details.");
      } else {
          console.warn("gtag is not defined. Cannot send view_cart event.");
      }
    },

    removeItem (id) {
      let index;
      let cart = this.$store.getters.cart;
      cart.forEach((i, ind) => {
        if (i.product.id == id) {
          index = ind
        }
      })
      cart.splice(index, 1);
      this.$store.dispatch('addToCart', cart)
    },

    removefoodItem (id,kg) {
      let index;
      let cart = this.$store.getters.foodcart;
      cart.forEach((i, ind) => {
        if (i.product.id == id && i.weight_type == kg) {
          index = ind
        }
      })
      cart.splice(index, 1);
      this.$store.dispatch('addTofoodCart', cart)
    },

    inputChange (id, product_id) {
      var value = document.getElementById(id).value;
      if (value == '') {
        document.getElementById(id).value = 1;
        value = 1;
      }
      this.updateCartQuantity(value, product_id, "input");
    },

    inputfoodChange (id, product_id, kg) {
      var el    = document.getElementById(id);
      let value =  el.value;

      if (value == '') {
        el.value = 1;
        value = 1;
      }

      this.updatefoodCartQuantity(value, product_id, "input", kg);
    },

    increaseQuantity (id, product_id) {
      var value = document.getElementById(id).value
      var v = Number(value) + 1;
      document.getElementById(id).value = v;
      this.updateCartQuantity(v, product_id, "+");
    },

    increasefoodQuantity (id, product_id, kg) {
      var el = document.getElementById(id)
      let value = el.value;
      var v = Number(value) + 1;

      el.value = v;
      this.updatefoodCartQuantity(v, product_id, "+", kg);
    },

    decreaseQuantity (id, product_id) {
      var value = document.getElementById(id).value;
      if (Number(value) > 1) {
        this.updateCartQuantity(value, product_id, "-");
      }
    },

    decreasefoodQuantity (id, product_id, kg) {
      var el = document.getElementById(id);
      var value = el.value;
      if (Number(value) > 1) {
        this.updatefoodCartQuantity(value, product_id, "-", kg);
      }
    },

    updateCartQuantity (value, product_id, action) {
      let cart_array = this.$store.getters.cart;
      cart_array.forEach(i => {
        if (product_id == i.product.id) {
          if (action == '+') {
            i.quantity = parseInt(i.quantity) + 1;
            i.price += parseInt(i.unit_price);
          }
          else if (action == '-') {
            i.quantity = parseInt(i.quantity) - 1;
            i.price -= parseInt(i.unit_price);
          }
          else {
            i.quantity = value;
            i.price = parseInt(i.unit_price) * value;

          }
        }
      })
      this.$store.dispatch('addToCart', cart_array)
    },

    updatefoodCartQuantity (value, product_id, action, kg) {
      let cart_array = this.$store.getters.foodcart;
      cart_array.forEach(i => {
        if (product_id == i.product.id && i.weight_type == kg) {
          if (action == '+') {
            i.quantity = parseInt(i.quantity) + 1;
            i.price += parseInt(i.unit_price);
          }
          else if (action == '-') {
            i.quantity = parseInt(i.quantity) - 1;
            i.price -= parseInt(i.unit_price);
          }
          else {
            i.quantity = value;
            i.price = parseInt(i.unit_price) * value;
          }
        }
      })
      this.$store.dispatch('addTofoodCart', cart_array)
    },

    restrictChars: function ($event) {
      if ($event.key !== '-') {
        return true
      }
      else {
        $event.preventDefault();
      }
    },

    handleCheckout () {
      if (this.cart_total >= 10000) {
        this.showChat()
        $(".modal").modal("hide");
        this.$router.push({ name: 'Cart' })
      }
      else {
        this.$toasted.show('The minimum order amount is N10000.')
      }
    },

    handleFoodCheckout () {
      if (this.food_cart_total >= 1000) {
        this.showChat()
        $(".modal").modal("hide");
        this.$router.push({ name: 'FoodCart' })
      }
      else {
        this.$toasted.show('The minimum order amount is N1000.')
      }
    },

    handleAllCheckout () {
      if ((this.food_cart_total + this.cart_total) >= 10000) {
        this.showChat()
        $(".modal").modal("hide");
        this.$router.push({ name: 'AllCart' })
      }
      else {
        this.$toasted.show('The minimum order amount is N10000.')
      }
    },

    formatPrice (price) {
      var str = price.toString().split(".");
      if (str[0].length >= 3) {
        str[0] = str[0].replace(/(\d)(?=(\d{3})+$)/g, "$1,");
      }
      if (!str[1]) {
        str[1] = "00";
      }
      return str.join(".");
    },
  },

  created (){
      this.store = this.$store.getters.store;
      this.delivery_mode= this.store.mode
      console.log(this.delivery_mode);
      let startstring = "Nov 22, 2023 00:00:59" //this.$store.getters.startstring;
      let futurestring = "Nov 29, 2023 23:59:59"// this.$store.getters.futurestring;
      let today        = new Date();
      let today_time   = today.getTime();
      let start_promo  = new Date(startstring).getTime();
      let end_time     = new Date(futurestring).getTime();
      this.countdown_left=(end_time-start_promo).days
      if(today_time <= end_time && today_time > start_promo){
          this.isPromo = true;
      }
      else{
          this.isPromo = false;
      }
  
  },

  

}
</script>


<style scoped>
.empty_btn {
  height: 40px;
  background: #000066 0% 0% no-repeat padding-box;
  border-radius: 2px;
  border: 0;
  color: #fff;
  font-size: 12px;
  width: 100%;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 30px;
}
.empty_btn:hover {
  background: #ffffff 0% 0% no-repeat padding-box;
  color: #000066;
  border: 1px solid #000066;
}
.small-red-focus{
  color:red;
  font-size:14px;
  text-align:center;
}
#sidecart .checkout {
    margin-top: 2mm !important;
}

#sidecart .foodcheckout button .foodtotal {
    background:  #000066;
    color:#ffffff;
    padding: 5px;
    margin: 0;
}
#sidecart .foodcheckout button {
    margin: auto;
    width: 80%;
    display: flex;
    justify-content: space-between;
    text-transform: uppercase;
    border: 1;
    border-color: #000066;
    background: #ffffff;
    padding: 5px;
    color: #000066;
    font-size: 13px;
    font-weight: 600;
}

#sidecart .foodcheckout button .warn {
    
    color: red;
    
}



.blinking{
    animation:blinkingText 1.5s infinite;
}
/*.cartcomponent #sidecart .modal-content .modal-header {
    border-bottom: 2px solid #131392 !important;
    display: flex;
    color: #131392;
}*/
@keyframes blinkingText{
    0%{     color: red; }
    49%{    color: red; }
    60%{    color: red; }
    65%{    color: red; }
    99%{    color:red;  }
    100%{   color: red;    }
}
.content-desktop {display: block;}
#content-mobile {display: none;}
@media screen and (max-width: 768px) {
.cartprice {
  width:40% !important
}
.content-desktop {display: none;}
#content-mobile {display: block;}

}

.cartheader{
 display:flex;
 margin-left: -33px;
}
.cartheader li{
list-style:none;
}

</style>